@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@layer base {
  @font-face {
    font-family: "Ciutadella Rounded";
    src: url("../public/fonts/ciutadella/CiutadellaRounded-SmBd.eot");
    src:
      url("../public/fonts/ciutadella/CiutadellaRounded-SmBd.woff2")
        format("woff2"),
      url("../public/fonts/ciutadella/CiutadellaRounded-SmBd.woff")
        format("woff"),
      url("../public/fonts/ciutadella/CiutadellaRounded-SmBd.ttf")
        format("truetype");
    font-display: swap;
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Ciutadella Rounded";
    src: url("../public/fonts/ciutadella/CiutadellaRounded-Bd.eot");
    src:
      url("../public/fonts/ciutadella/CiutadellaRounded-Bd.woff2")
        format("woff2"),
      url("../public/fonts/ciutadella/CiutadellaRounded-Bd.woff") format("woff"),
      url("../public/fonts/ciutadella/CiutadellaRounded-Bd.ttf")
        format("truetype");
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Ciutadella Rounded";
    src: url("../public/fonts/ciutadella/CiutadellaRounded-Md.eot");
    src:
      url("../public/fonts/ciutadella/CiutadellaRounded-Md.woff2")
        format("woff2"),
      url("../public/fonts/ciutadella/CiutadellaRounded-Md.woff") format("woff"),
      url("../public/fonts/ciutadella/CiutadellaRounded-Md.ttf")
        format("truetype");
    font-display: swap;
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Ciutadella Rounded";
    src: url("../public/fonts/ciutadella/CiutadellaRounded-Rg.eot");
    src:
      url("../public/fonts/ciutadella/CiutadellaRounded-Rg.woff2")
        format("woff2"),
      url("../public/fonts/ciutadella/CiutadellaRounded-Rg.woff") format("woff"),
      url("../public/fonts/ciutadella/CiutadellaRounded-Rg.ttf")
        format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "DM Serif Display";
    src: url("../public/fonts/dmfont/DMSerifDisplay-Regular.ttf")
      format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: regular;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("../public/fonts/opensans/OpenSans-VariableFont_wdth_wght.ttf")
      format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: regular;
  }

  /* faktum font add */

  @font-face {
    font-family: "faktum";
    src: url("../public/fonts/faktum/Faktum-Regular.ttf") format("truetype");
    font-display: swap;
    font-weight: 400;
    font-style: regular;
  }

  @font-face {
    font-family: "faktum";
    src: url("../public/fonts/faktum/Faktum-Bold.ttf") format("truetype");
    font-display: swap;
    font-weight: 700;
    font-style: regular;
  }

  @font-face {
    font-family: "faktum";
    src: url("../public/fonts/faktum/Faktum-SemiBold.ttf") format("truetype");
    font-display: swap;
    font-weight: 600;
    font-style: regular;
  }

  @font-face {
    font-family: "faktum";
    src: url("../public/fonts/faktum/Faktum-SemiBold.ttf") format("truetype");
    font-display: swap;
    font-weight: 500;
    font-style: regular;
  }

  body {
    font-family: "proxima-nova", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #2e2e2e;
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #272427;
  font-weight: 700;
  line-height: 27px;
}

.MuiAutocomplete-popper {
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 12px 0px rgba(15, 23, 42, 0.11);
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 8px;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .Mui-checked {
  color: #272427;
}

@media (min-width: 600px) {
  .MuiAutocomplete-popper {
    max-width: 400px;
    width: 100% !important;
  }
}

@media (min-width: 904px) {
  .MuiAutocomplete-popper {
    max-width: 414px;
  }
}
.MuiDrawer-modal .MuiPaper-root {
  height: 100% !important;
  border-bottom-left-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.MuiAutocomplete-noOptions {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 18px !important;
  color: #272427 !important;
  font-weight: 700 !important;
}

.MuiAutocomplete-loading {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
  font-size: 18px !important;
  color: #272427 !important;
  font-weight: 700 !important;
}

.MuiDrawer-modal .MuiPaper-root ul {
  list-style-type: disc;
}

.MuiDrawer-modal .MuiPaper-root ol {
  list-style-type: decimal;
}

body:has(.page-not-found) #progress-bar {
  display: none;
}

body:not(:has(#progress-bar)) .min-h-\[calc\(100vh-12px\)\] {
  min-height: 100vh;
}

body:not(:has(#progress-bar)) .home-page_homepageWrapper__wSEsG {
  min-height: 100vh;
}
